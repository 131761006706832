@tailwind base;
@tailwind components;
@tailwind utilities;

@layer base {
  h1 {
    @apply m-0 not-italic font-secondary font-bold text-3xl pb-[50px] before:mr-1 before:content-['/'];
  }
  h2 {
    @apply m-0 not-italic font-secondary font-bold text-2xl pb-[10px];
  }
  h3 {
    @apply m-0 not-italic font-secondary font-bold text-xl;
  }
  h4 {
    @apply m-0;
  }
  h5 {
    @apply m-0;
  }

  button,
  a {
    @apply cursor-pointer;
  }
}

@layer utilities {
  .clickable {
    @apply cursor-pointer font-medium underline decoration-inherit decoration-1 underline-offset-[0.1em] duration-500 hover:decoration-3 hover:duration-200;
  }
}

*,
*:before,
*:after {
  box-sizing: border-box;
}

button,
a {
  cursor: pointer;
}

.rounded-image {
  border-radius: 50%;
}

/* Utilise the "full-bleed" grid layout from https://www.joshwcomeau.com/css/full-bleed/ */
.container {
  display: grid;
  grid-template-columns: 1fr calc(100% - 310px) 1fr;
  max-width: 100%;
}
.container > .full-bleed {
  grid-column: 1 / -1;
  padding-left: 0;
  padding-right: 0;
}
.container > * {
  grid-column: 2;
  padding-left: 20px;
  padding-right: 20px;
}
@media (max-width: 1024px) {
  .container {
    grid-template-columns: 1fr calc(100% - 40px) 1fr;
  }
}
@media (max-width: 640px) {
  .container > * {
    padding-left: 15px;
    padding-right: 15px;
  }
}
@media (max-width: 400px) {
  .container > * {
    padding-left: 5px;
    padding-right: 5px;
  }
}
@media print {
  .container[data-print-referal-qr-mode] {
    display: none;
  }
}

/* --margin-horizontal: 155px;
  --margin-vertical: 45px; */

/* .container {
  --padding: var(--margin-horizontal);
  display: grid;
  grid-template-columns:
    1fr
    calc(100% - 2 * var(--padding))
    1fr;
  grid-column-gap: var(--padding);
}
.container > * {
  grid-column: 2;
}
.container > .full-bleed {
  grid-column: 1 / -1;
  width: 100%;
}

.background-light {
  background-color: var(--cream);
  color: var(--colour-primary);
}
.background-dark {
  background-color: var(--colour-primary);
  color: var(--blue-light);
}

@media (max-width: 1200px) {
  :root {
    --margin-horizontal: 20px;
  }
} */
